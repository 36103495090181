// src/components/Message.tsx

import React from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';

interface MessageProps {
    content: string;
    aiMessage: boolean;
    aiProvider?: string;
    model?: string;
    animate?: boolean;
    onSendAI?: (content: string) => void; // Nova prop
}

const Message: React.FC<MessageProps> = ({
                                             content,
                                             aiMessage,
                                             aiProvider,
                                             model,
                                             onSendAI,
                                         }) => {
    /**
     * Copiar texto para o clipboard
     */
    const handleCopyToClipboard = () => {
        navigator.clipboard
            .writeText(content)
            .then(() => {
                alert('Conteúdo copiado para o clipboard!');
            })
            .catch((error) => {
                console.error('Falha ao copiar para o clipboard:', error);
            });
    };

    /**
     * Baixar o conteúdo em um arquivo .txt
     */
    const handleDownloadTxt = () => {
        // Cria um Blob com o conteúdo em texto
        const blob = new Blob([content], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);

        // Cria um link <a> dinamicamente
        const link = document.createElement('a');
        link.href = url;
        link.download = 'content.txt'; // nome do arquivo

        // Dispara o clique para iniciar download
        document.body.appendChild(link);
        link.click();

        // Remove o elemento e libera memória
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    /**
     * Reenviar conteúdo para a IA
     */
    const handleSendAIAgain = () => {
        if (onSendAI) {
            onSendAI(content); // Chama a função passada via prop
        }
    };

    // ------------------------------
    // Mensagem gerada por AI
    // ------------------------------
    if (aiMessage) {
        return (
            <div className="flex flex-col mb-4">
                <div className="flex items-center">
                    <span className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center mr-3">
                        🤖
                    </span>
                    <div className="bg-gray-100 p-3 rounded flex-1 max-w-6xl overflow-auto">
                        <Markdown
                            remarkPlugins={[remarkGfm]}
                            rehypePlugins={[rehypeRaw]}
                            components={{
                                code({ node, inline, className, children, ...props }: any) {
                                    const match = /language-(\w+)/.exec(className || '');
                                    return !inline && match ? (
                                        <SyntaxHighlighter
                                            style={dracula}
                                            PreTag="div"
                                            language={match[1]}
                                            {...props}
                                        >
                                            {String(children).replace(/\n$/, '')}
                                        </SyntaxHighlighter>
                                    ) : (
                                        <code className={className} {...props}>
                                            {children}
                                        </code>
                                    );
                                },
                            }}
                        >
                            {content}
                        </Markdown>

                        <div className="mt-2 flex justify-between items-center">
                            <div>
                                {/* Botão de Copiar */}
                                <button
                                    className="hover:bg-blue-500 text-blue-500 hover:text-white font-bold py-1 px-2 rounded text-xl"
                                    onClick={handleCopyToClipboard}
                                >
                                    <i className="bi bi-clipboard"></i>
                                </button>

                                {/* Botão de Enviar para IA */}
                                <button
                                    className="hover:bg-purple-500 text-purple-500 hover:text-white font-bold py-1 px-2 rounded text-xl"
                                    onClick={handleSendAIAgain}
                                >
                                    <i className="bi bi-send-check"></i>
                                </button>

                                {/* Botão de Download */}
                                <button
                                    className="hover:bg-blue-500 text-blue-500 hover:text-white font-bold py-1 px-2 rounded text-xl"
                                    onClick={handleDownloadTxt}
                                >
                                    <i className="bi bi-download"></i>
                                </button>

                                {/* Botões de Like/Dislike */}
                                <button
                                    className="hover:bg-green-500 text-green-500 hover:text-white font-bold py-1 px-2 rounded text-xl"
                                    onClick={() => {/* Implementar Like */}}
                                >
                                    <i className="bi bi-hand-thumbs-up-fill"></i>
                                </button>
                                <button
                                    className="hover:bg-red-500 text-red-500 hover:text-white font-bold py-1 px-2 rounded text-xl"
                                    onClick={() => {/* Implementar Dislike */}}
                                >
                                    <i className="bi bi-hand-thumbs-down-fill"></i>
                                </button>
                            </div>
                            <div className="text-right">
                                {aiProvider && (
                                    <span className="inline-block bg-blue-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
                                        {aiProvider}
                                    </span>
                                )}
                                {model && (
                                    <span className="inline-block bg-red-600 text-white text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
                                        {model}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    // ------------------------------
    // Mensagem do usuário
    // ------------------------------
    // --- Adicionar o botão action para enviar o {content} com handleSendAI
    return (
        <div className="flex items-center justify-end mb-4">
            <div className="bg-blue-100 p-3 rounded max-w-6xl overflow-auto">
                <Markdown
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                    components={{
                        code({ node, inline, className, children, ...props }: any) {
                            const match = /language-(\w+)/.exec(className || '');
                            return !inline && match ? (
                                <SyntaxHighlighter
                                    style={dracula}
                                    PreTag="div"
                                    language={match[1]}
                                    {...props}
                                >
                                    {String(children).replace(/\n$/, '')}
                                </SyntaxHighlighter>
                            ) : (
                                <code className={className} {...props}>
                                    {children}
                                </code>
                            );
                        },
                    }}
                >
                    {content}
                </Markdown>
            </div>
            <span className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center ml-3">
                👤
            </span>

            {/* Botão de Enviar para AI */}
            <button
                className="ml-3 hover:bg-purple-500 text-purple-500 hover:text-white font-bold py-1 px-2 rounded text-xl"
                onClick={handleSendAIAgain}
            >
                <i className="bi bi-send-check"></i>
            </button>
        </div>
    );
};

export default Message;
