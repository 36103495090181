// src/components/ChatHeader.tsx

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import {
  setAiProvider,
  setModel,
  setUseHistory,
  setSelectedPersona,
} from '../../store/slices/aiSlice';

import { getToken } from '../../service/authService';

const { REACT_APP_API_ENDPOINT } = process.env;

const ChatHeader: React.FC = () => {
  const dispatch = useDispatch();

  // Estados do Redux
  const aiProvider = useSelector((state: RootState) => state.ai.aiProvider);
  const model = useSelector((state: RootState) => state.ai.model);
  const useHistory = useSelector((state: RootState) => state.ai.useHistory);
  const selectedPersona = useSelector((state: RootState) => state.ai.selectedPersona);

  // Estado para armazenar as categorias retornadas em /categories/persona
  const [categories, setCategories] = useState<{ id: number; title: string }[]>([]);
  // Categoria selecionada
  const [selectedCategory, setSelectedCategory] = useState<number | ''>('');

  // Estado para armazenar as personas daquela categoria
  const [personas, setPersonas] = useState<{ id: number; title: string }[]>([]);

  // Modelos com base no provider selecionado
  const models = useSelector((state: RootState) => {
    switch (aiProvider) {
      case 'groq':
        return [
          'llama-3.3-70b-versatile',
          'llama-3.2-3b-preview',
          'llama-3.2-90b-vision-preview',
          'llama3-8b-8192',
        ];
      case 'gpt':
        return ['gpt-4o', 'gpt-4o-mini', 'gpt-4-turbo', 'gpt-3.5-turbo-0125'];
      case 'claude':
        return ['claude-3-5-sonnet-20241022', 'claude-3-5-sonnet-20240620'];
      default:
        return [];
    }
  });

  // Ajusta o model selecionado caso o atual não exista na lista
  useEffect(() => {
    if (models.length > 0 && !models.includes(model)) {
      dispatch(setModel(models[0]));
    }
  }, [aiProvider, dispatch, models, model]);

  /**
   * Faz a chamada para obter as categorias que possuem associação a persona.
   * Endpoint: GET /categories/persona
   */
  useEffect(() => {
    const fetchCategoriesWithPersona = async () => {
      try {
        const token = getToken();
        const url = `${REACT_APP_API_ENDPOINT}/categories/persona`;

        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Falha ao buscar categorias de persona');
        }

        const data = await response.json();

        // Se data for null ou não for um array, cria array vazio
        if (!data || !Array.isArray(data)) {
          setCategories([]);
          return;
        }

        setCategories(data);
      } catch (error) {
        console.error('Erro ao buscar categorias/persona:', error);
      }
    };

    fetchCategoriesWithPersona();
  }, []);

  /**
   * Quando a categoria for alterada, buscar as personas específicas daquela categoria.
   * Endpoint: GET /personas/category/{categoryId}
   */
  useEffect(() => {
    const fetchPersonasByCategory = async (categoryId: number | '') => {
      try {
        const token = getToken();

        // Se não há categoria selecionada, podemos limpar as personas
        if (!categoryId) {
          setPersonas([]);
          return;
        }

        const url = `${REACT_APP_API_ENDPOINT}/personas/category/${categoryId}`;
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Falha ao buscar personas por categoria');
        }

        const data = await response.json();

        // Tratar o caso de data nulo ou não-array
        if (!data || !Array.isArray(data)) {
          setPersonas([]);
          return;
        }

        setPersonas(data);
      } catch (error) {
        console.error('Erro ao buscar personas por categoria:', error);
        // Em caso de erro, podemos deixar personas vazio
        setPersonas([]);
      }
    };

    fetchPersonasByCategory(selectedCategory);
  }, [selectedCategory]);

  /**
   * Handlers para cada campo
   */
  const handleAiProviderChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(setAiProvider(event.target.value));
  };

  const handleModelChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(setModel(event.target.value));
  };

  const handleUseHistoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setUseHistory(event.target.checked));
  };

  const handlePersonaChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(setSelectedPersona(Number(event.target.value)));
  };

  const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value ? Number(event.target.value) : '';
    setSelectedCategory(value);

    // Se quiser resetar a persona selecionada para obrigar nova escolha:
    dispatch(setSelectedPersona(0));
  };

  return (
      <div className="px-4 py-2 bg-gray-100 flex flex-col">
        <div className="p-3 flex items-center space-x-2">
          <div className="mr-8">
            <i className="bi bi-robot mr-2" /> <b>Maria Rita</b>
          </div>

          {/* Seletor de Provider */}
          <label htmlFor="aiProvider" className="text-sm font-medium text-gray-700">
            Escolher AI:
          </label>
          <select
              id="aiProvider"
              className="bg-white border border-gray-300 p-2 rounded"
              value={aiProvider}
              onChange={handleAiProviderChange}
          >
            <option value="groq">Groq</option>
            <option value="gpt">GPT</option>
            <option value="claude">Claude</option>
          </select>

          {/* Seletor de Model */}
          <label htmlFor="model" className="text-sm font-medium text-gray-700">
            Modelos:
          </label>
          <select
              id="model"
              className="bg-white border border-gray-300 p-2 rounded"
              value={model}
              onChange={handleModelChange}
          >
            {models.map((m, index) => (
                <option key={index} value={m}>
                  {m}
                </option>
            ))}
          </select>

          {/* Seletor de Categoria */}
          <label htmlFor="category" className="text-sm font-medium text-gray-700">
            Categoria:
          </label>
          <select
              id="category"
              className="bg-white border border-gray-300 p-2 rounded"
              value={selectedCategory}
              onChange={handleCategoryChange}
          >
            <option value="">Todas</option>
            {categories.map((cat) => (
                <option key={cat.id} value={cat.id}>
                  {cat.title}
                </option>
            ))}
          </select>

          {/* Seletor de Persona (retornada do endpoint /personas/category/{id}) */}
          <label htmlFor="persona" className="text-sm font-medium text-gray-700">
            Persona:
          </label>
          <select
              id="persona"
              className="bg-white border border-gray-300 p-2 rounded"
              value={selectedPersona}
              onChange={handlePersonaChange}
          >
            <option value="">Selecione</option>
            {personas.map((persona) => (
                <option key={persona.id} value={persona.id}>
                  {persona.title}
                </option>
            ))}
          </select>

          {/* CheckBox: usar histórico */}
          <div className="flex items-center">
            <input
                id="useHistory"
                type="checkbox"
                checked={useHistory}
                onChange={handleUseHistoryChange}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <label htmlFor="useHistory" className="ml-2 text-sm text-gray-700">
              Usar histórico
            </label>
          </div>
        </div>
        <hr className="border-t-2 border-gray-300 w-full p-1" />
      </div>
  );
};

export default ChatHeader;
