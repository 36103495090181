import React, { useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../service/authService';
import { setSession } from '../store/slices/sessionSlice';
import { RootState } from '../store';

const { REACT_APP_API_ENDPOINT } = process.env;

const CreateSession: React.FC = () => {
  const [sessionTitle, setSessionTitle] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentProject = useSelector((state: RootState) => state.project.currentProject);

  const handleCreateSession = async () => {
    if (!currentProject) {
      console.error('No project selected');
      return;
    }

    const token = getToken();
    const url = `${REACT_APP_API_ENDPOINT}/chatbot/sessions`;
    
    const payload = {
      title: sessionTitle,
      projectId: currentProject.id,
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Failed to create session');
      }

      const data = await response.json();
      console.log('Session Created:', data);

      // Armazenar os dados da sessão no Redux
      dispatch(setSession(data));

      // Redirecionar para a rota dashboard
      navigate('/dashboard');
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Create Session</h1>
      <input
        type="text"
        className="border p-2 mb-4 w-full"
        placeholder="Session Title"
        value={sessionTitle}
        onChange={(e) => setSessionTitle(e.target.value)}
      />
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        onClick={handleCreateSession}
      >
        Create Session
      </button>
      <Link
          to="/dashboard"
          className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded mx-4"
      >
        Return to Dashboard
      </Link>
    </div>
  );
};

export default CreateSession;
