// store/slices/authSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Definindo uma interface para o usuário
interface User {
  id: string;
  name: string;
  email: string;
  // Adicione outros campos conforme necessário
}

interface AuthState {
  token: string | null;
  user: User | null;
}

const tokenFromStorage = localStorage.getItem('token');
const userDataFromStorage = localStorage.getItem('userData');

let parsedUserData: User | null = null;
if (userDataFromStorage) {
  try {
    parsedUserData = JSON.parse(userDataFromStorage) as User;
  } catch (error) {
    console.error('Erro ao fazer parse do userData do localStorage:', error);
  }
}

const initialState: AuthState = {
  token: tokenFromStorage,
  // Se userDataFromStorage existir e for válido, usamos esse objeto; senão, fica null.
  user: parsedUserData,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
      localStorage.setItem('token', action.payload);
    },
    setUser(state, action: PayloadAction<User>) {
      state.user = action.payload;
      localStorage.setItem('userData', JSON.stringify(action.payload));
    },
    logout(state) {
      state.token = null;
      state.user = null;
      localStorage.removeItem('token');
      localStorage.removeItem('userData');
    },
  },
});

export const { setToken, setUser, logout } = authSlice.actions;
export default authSlice.reducer;
