// store/slices/aiSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

interface Message {
  id: string;
  content: string;
  aiMessage: boolean;
  aiProvider: string;
  model: string;
  isSystem: boolean;
  isRag: boolean;
  isPersona: boolean;
}

interface ChatMessage {
  id: number;
  user: string;
  content: string;
  aiProvider: string;
  model: string;
  isSystem: boolean;
  isRag: boolean;
  isPersona: boolean;
}

interface AiState {
  aiProvider: string;
  model: string;
  response: string;
  useHistory: boolean;
  messages: Message[];
  selectedPersona: number;
  userMessage: string;  // <-- ADICIONADO (campo para guardar o Prompt clicado)
}

const loadMessagesFromLocalStorage = (): AiState['messages'] => {
  try {
    const serializedMessages = localStorage.getItem('messages');
    if (serializedMessages === null) {
      return [];
    }
    return JSON.parse(serializedMessages);
  } catch (err) {
    console.error('Could not load messages', err);
    return [];
  }
};

const saveMessagesToLocalStorage = (messages: AiState['messages']) => {
  try {
    const serializedMessages = JSON.stringify(messages);
    localStorage.setItem('messages', serializedMessages);
  } catch (err) {
    console.error('Could not save messages', err);
  }
};

const initialState: AiState = {
  aiProvider: 'groq',
  model: '',
  response: '',
  useHistory: true,
  messages: loadMessagesFromLocalStorage(),
  selectedPersona: 0,
  userMessage: '', // inicia vazio
};

const aiSlice = createSlice({
  name: 'ai',
  initialState,
  reducers: {
    setAiProvider(state, action: PayloadAction<string>) {
      state.aiProvider = action.payload;
    },
    setModel(state, action: PayloadAction<string>) {
      state.model = action.payload;
    },
    setResponse(state, action: PayloadAction<string>) {
      state.response = action.payload;
    },
    setUseHistory(state, action: PayloadAction<boolean>) {
      state.useHistory = action.payload;
    },
    setSelectedPersona(state, action: PayloadAction<number>) {
      state.selectedPersona = action.payload;
    },

    // NOVA ACTION: define o userMessage
    setUserMessage(state, action: PayloadAction<string>) {
      state.userMessage = action.payload;
    },

    addMessage(state, action: PayloadAction<Message>) {
      state.messages.push(action.payload);
      saveMessagesToLocalStorage(state.messages);
    },

    setMessages(state, action: PayloadAction<ChatMessage[]>) {
      state.messages = [];
      action.payload.forEach((message: ChatMessage) => {
        const userMsg: Message = {
          id: uuidv4(),
          content: message.user,
          aiMessage: false,
          aiProvider: message.aiProvider,
          model: message.model,
          isSystem: message.isSystem,
          isRag: message.isRag,
          isPersona: message.isPersona,
        };
        state.messages.push(userMsg);

        const aiMsg: Message = {
          id: message.id.toString(),
          content: message.content,
          aiMessage: true,
          aiProvider: message.aiProvider,
          model: message.model,
          isSystem: message.isSystem,
          isRag: message.isRag,
          isPersona: message.isPersona,
        };
        state.messages.push(aiMsg);
      });
      saveMessagesToLocalStorage(state.messages);
    },

    clearMessages(state) {
      console.log('>>>>>>>> clearMessages');
      state.messages = [];
      saveMessagesToLocalStorage(state.messages);
    },
  },
});

export const {
  setAiProvider,
  setModel,
  setResponse,
  addMessage,
  setMessages,
  setUseHistory,
  setSelectedPersona,
  clearMessages,
  // Exportamos a nova action
  setUserMessage,
} = aiSlice.actions;

export default aiSlice.reducer;
