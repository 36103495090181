import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getToken } from '../service/authService';

const { REACT_APP_API_ENDPOINT } = process.env;

const UpdatedPersona: React.FC = () => {
    const [personaId, setPersonaId] = useState<string>('');
    const [title, setTitle] = useState<string>('');
    const [content, setContent] = useState<string>('');
    const [categories, setCategories] = useState<any[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<string>('');
    const [personas, setPersonas] = useState<any[]>([]);
    const token = getToken();

    // Fetch all categories and personas
    useEffect(() => {
        fetchCategories();
        fetchAllPersonas();
    }, [token]);

    const fetchCategories = async () => {
        try {
            const response = await fetch(`${REACT_APP_API_ENDPOINT}/categories/persona`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if (!response.ok) throw new Error('Failed to fetch categories');
            setCategories(await response.json() ?? []);
        } catch (error) {
            console.error('Error fetching categories:', error);
            setCategories([]);
        }
    };

    const fetchAllPersonas = async () => {
        try {
            const response = await fetch(`${REACT_APP_API_ENDPOINT}/personas`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if (!response.ok) throw new Error('Failed to fetch personas');
            setPersonas(await response.json() ?? []);
        } catch (error) {
            console.error('Error fetching personas:', error);
            setPersonas([]);
        }
    };

    const fetchPersonasByCategory = async (categoryId: string) => {
        try {
            if (!categoryId) {
                fetchAllPersonas();
                return;
            }

            const response = await fetch(`${REACT_APP_API_ENDPOINT}/personas/category/${categoryId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if (!response.ok) throw new Error('Failed to fetch personas by category');
            setPersonas(await response.json() ?? []);
        } catch (error) {
            console.error('Error fetching personas by category:', error);
            setPersonas([]);
        }
    };

    const fetchPersonaById = async (id: string) => {
        try {
            const response = await fetch(`${REACT_APP_API_ENDPOINT}/personas/${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if (!response.ok) throw new Error('Failed to fetch persona');
            const data = await response.json();
            setPersonaId(data.id);
            setTitle(data.title ?? '');
            setContent(data.content ?? '');
            setSelectedCategory(data.categoryId ?? '');
        } catch (error) {
            console.error('Error fetching persona:', error);
        }
    };

    const handleCategoryFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const categoryId = e.target.value;
        setSelectedCategory(categoryId);
        fetchPersonasByCategory(categoryId);
    };

    const handleEdit = (id: string) => {
        fetchPersonaById(id);
    };

    const handleDelete = async (id: string) => {
        try {
            const response = await fetch(`${REACT_APP_API_ENDPOINT}/personas/${id}`, {
                method: 'DELETE',
                headers: { Authorization: `Bearer ${token}` },
            });
            if (!response.ok) throw new Error('Failed to delete persona');
            fetchAllPersonas();
        } catch (error) {
            console.error('Error deleting persona:', error);
        }
    };

    const handleSave = async () => {
        const url = personaId
            ? `${REACT_APP_API_ENDPOINT}/personas/${personaId}`
            : `${REACT_APP_API_ENDPOINT}/personas`;
        const method = personaId ? 'PUT' : 'POST';

        // Converter para number
        const categoryIdAsNumber = selectedCategory ? parseInt(selectedCategory, 10) : null;

        const payload = {
            title,
            content,
            categoryId: categoryIdAsNumber,  // Agora é number em vez de string
        };
        
        try {
            const response = await fetch(url, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(payload),
            });
            if (!response.ok) throw new Error(`Failed to ${personaId ? 'update' : 'create'} persona`);
            fetchAllPersonas();
            resetForm();
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const resetForm = () => {
        setPersonaId('');
        setTitle('');
        setContent('');
        setSelectedCategory('');
    };

    return (
        <div className="p-8 bg-gray-100 min-h-screen">
            <h1 className="text-3xl font-bold mb-6 text-center">
                {personaId ? 'Edit Persona' : 'Manage Personas'}
            </h1>

            {/* Category Filter */}
            <div className="mb-6">
                <label htmlFor="categorySelect" className="block text-lg font-medium mb-2">
                    Filter by Category:
                </label>
                <select
                    id="categorySelect"
                    className="border p-2 w-full rounded"
                    value={selectedCategory}
                    onChange={handleCategoryFilter}
                >
                    <option value="">-- All Categories --</option>
                    {categories.map((category) => (
                        <option key={category.id} value={category.id}>
                            {category.title}
                        </option>
                    ))}
                </select>
            </div>

            {/* Create/Edit Form */}
            <div className="mb-6 bg-white p-6 rounded-lg shadow-md">
                <h2 className="text-2xl font-bold mb-4">{personaId ? 'Edit Persona' : 'Create Persona'}</h2>
                <input
                    type="text"
                    placeholder="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="border p-2 mb-4 w-full rounded"
                />
                <textarea
                    placeholder="Content"
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    className="border p-2 mb-4 w-full rounded"
                    rows={4}
                />
                <select
                    id="categoryCombo"
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    className="border p-2 mb-4 w-full rounded"
                >
                    <option value="">-- Select Category --</option>
                    {categories.map((category) => (
                        <option key={category.id} value={category.id}>
                            {category.title}
                        </option>
                    ))}
                </select>
                <div className="flex space-x-4">
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded"
                        onClick={handleSave}
                    >
                        {personaId ? 'Update' : 'Create'}
                    </button>
                    <button
                        className="bg-gray-500 hover:bg-gray-700 text-white py-2 px-4 rounded"
                        onClick={resetForm}
                    >
                        Cancel
                    </button>
                    <Link
                        to="/dashboard"
                        className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded"
                    >
                        Return to Dashboard
                    </Link>
                </div>
            </div>

            {/* Personas Table */}
            <div className="bg-white p-6 rounded-lg shadow-md">
                <h2 className="text-2xl font-bold mb-4">Personas List</h2>
                <table className="table-auto w-full border-collapse border border-gray-300">
                    <thead>
                    <tr className="bg-gray-200">
                        <th className="border p-2">ID</th>
                        <th className="border p-2">Title</th>
                        <th className="border p-2">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {personas.length > 0 ? (
                        personas.map((persona) => (
                            <tr key={persona.id} className="hover:bg-gray-100">
                                <td className="border p-2 text-center">{persona.id}</td>
                                <td className="border p-2">{persona.title ?? 'N/A'}</td>
                                <td className="border p-2 flex justify-center space-x-2">
                                    <button
                                        className="bg-yellow-500 hover:bg-yellow-700 text-white py-1 px-3 rounded"
                                        onClick={() => handleEdit(persona.id)}
                                    >
                                        Edit
                                    </button>
                                    <button
                                        className="bg-red-500 hover:bg-red-700 text-white py-1 px-3 rounded"
                                        onClick={() => handleDelete(persona.id)}
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={3} className="text-center p-4">No personas available.</td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default UpdatedPersona;
