// src/components/ChatInput.tsx

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import {
  setResponse,
  addMessage,
  setUserMessage,  // <-- Importar a nova action
} from '../../store/slices/aiSlice';
import { AIRequestPayload, sendAIRequest } from '../../service/aiService';
import { v4 as uuidv4 } from 'uuid';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const ChatInput: React.FC = () => {
  // Agora, pegamos userMessage do Redux
  const userMessage = useSelector((state: RootState) => state.ai.userMessage);

  // System e Rag ainda ficam locais, pois não vêm de Prompt
  const [systemMessage, setSystemMessage] = useState('');
  const [ragMessage, setRagMessage] = useState('');

  const aiProvider = useSelector((state: RootState) => state.ai.aiProvider);
  const model = useSelector((state: RootState) => state.ai.model);
  const useHistory = useSelector((state: RootState) => state.ai.useHistory);
  const personaId = useSelector((state: RootState) => state.ai.selectedPersona);
  const currentSession = useSelector((state: RootState) => state.session.currentSession);
  const [error, setError] = useState<string | null>(null);

  const dispatch = useDispatch();

  // Handler para mudar o userMessage
  const handleUserMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(setUserMessage(e.target.value));
  };

  const handleSendMessage = async () => {
    setError(null);

    const user = userMessage;   // do Redux
    const system = systemMessage;
    const rag = ragMessage;

    // Limpamos o userMessage no Redux, para zerar o campo
    dispatch(setUserMessage(''));
    setSystemMessage('');
    setRagMessage('');

    const userMessageObj = {
      id: uuidv4(),
      content: user,
      aiMessage: false,
      aiProvider: aiProvider,
      model: model,
      isSystem: personaId > 0 || system.trim().length > 0,
      isRag: rag.trim().length > 0,
      isPersona: personaId > 0,
    };

    dispatch(addMessage(userMessageObj)); // Mensagem do usuário na conversa

    try {
      const payload: AIRequestPayload = {
        aiProvider,
        model,
        stream: false,
        user,
        system,
        rag,
        useHistory,
        chatHistory: '',
        sessionId: currentSession?.id || null,
        personaId,
      };

      const response = await sendAIRequest(payload);
      dispatch(setResponse(response.content));

      const aiMessage = {
        id: uuidv4(),
        content: response.content,
        aiMessage: true,
        aiProvider: response.aiProvider,
        model: response.model,
        isSystem: response.isSystem,
        isRag: response.isRag,
        isPersona: response.isPersona,
      };
      dispatch(addMessage(aiMessage));
    } catch (error) {
      console.error('Error:', error);
      setError(`Ocorreu um erro ao enviar a mensagem. ${error}`);
    }
  };

  return (
      <div className="p-4 bg-gray-200 flex items-start">
        <div className="flex-1">
          {error && <div className="mt-2 text-red-500">{error}</div>}
          <Tabs>
            <TabList>
              <Tab>User</Tab>
              <Tab>System</Tab>
              <Tab>Rag</Tab>
            </TabList>

            <TabPanel>
            <textarea
                className="w-full p-2 border border-gray-300 rounded"
                rows={4}
                placeholder="Digite a mensagem do usuário..."
                value={userMessage}                 // <-- Lê do Redux
                onChange={handleUserMessageChange}  // <-- Atualiza Redux
            />
            </TabPanel>

            <TabPanel>
            <textarea
                className="w-full p-2 border border-gray-300 rounded"
                rows={4}
                placeholder="Digite a mensagem do sistema..."
                value={systemMessage}
                onChange={(e) => setSystemMessage(e.target.value)}
            />
            </TabPanel>

            <TabPanel>
            <textarea
                className="w-full p-2 border border-gray-300 rounded"
                rows={4}
                placeholder="Digite a mensagem RAG..."
                value={ragMessage}
                onChange={(e) => setRagMessage(e.target.value)}
            />
            </TabPanel>
          </Tabs>
        </div>
        <div className="ml-4">
          <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={handleSendMessage}
              disabled={!userMessage.trim()}
          >
            <i className="bi bi-arrow-right"></i>
          </button>
        </div>
      </div>
  );
};

export default ChatInput;
