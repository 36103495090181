// src/components/Prompt.tsx

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { getToken } from '../../service/authService';
import { Link } from 'react-router-dom';

// (1) Importe a nova action
import { setUserMessage } from '../../store/slices/aiSlice';

const { REACT_APP_API_ENDPOINT } = process.env;

interface Category {
  id: number;
  title: string;
}

interface PromptItem {
  id: number;
  title: string;
  content: string;
  categoryId: number;
}

const Prompt: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.auth.user);

  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<number | ''>('');
  const [prompts, setPrompts] = useState<PromptItem[]>([]);

  const token = getToken();

  useEffect(() => {
    fetchCategories();
  }, [token]);

  const fetchCategories = async () => {
    try {
      const response = await fetch(`${REACT_APP_API_ENDPOINT}/categories/prompt`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Falha ao buscar categorias de prompt');
      }

      const data = await response.json();
      if (Array.isArray(data)) {
        setCategories(data);
      } else {
        setCategories([]);
      }
    } catch (error) {
      console.error(error);
      setCategories([]);
    }
  };

  const fetchPromptsByCategory = async (categoryId: number) => {
    try {
      const response = await fetch(`${REACT_APP_API_ENDPOINT}/prompts/category/${categoryId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Falha ao buscar prompts por categoria');
      }

      const data = await response.json();
      if (Array.isArray(data)) {
        setPrompts(data);
      } else {
        setPrompts([]);
      }
    } catch (error) {
      console.error(error);
      setPrompts([]);
    }
  };

  const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const catId = Number(event.target.value);
    setSelectedCategory(catId);

    if (!catId) {
      setPrompts([]);
      return;
    }

    fetchPromptsByCategory(catId);
  };

  const handlePromptClick = (content: string) => {
    // Dispara a ação que grava o content do Prompt no Redux
    dispatch(setUserMessage(content));

    // Opcional: redirecionar para /chat (conforme seu roteamento)
    // history.push('/chat') // React Router v5
    // useNavigate('/chat')  // React Router v6
  };

  return (
      <div className="w-64 bg-gray-100 h-full">
        <div className="p-4">
          {user && (
              <div className="flex items-center space-x-3 mb-4">
                <i className="bi bi-person-circle text-2xl" />
                <span className="text-xl font-bold">{user.name}</span>
              </div>
          )}

          <hr className="border-t-2 border-gray-300 w-full p-1" />

          <Link to="/create-category">
            <button className="bg-red-500 hover:bg-red-800 text-white font-bold py-2 px-4 rounded mb-4">
              <i className="bi bi-plus mr-2"></i>Categorias
            </button>
          </Link>

          <h3 className="flex items-center font-bold text-lg mb-2">
            <i className="bi bi-lightbulb-fill mr-2"></i> Prompts
          </h3>

          <Link to="/create-prompt">
            <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mb-4">
              <i className="bi bi-plus mr-2"></i>Criar um Prompt
            </button>
          </Link>

          <div className="mb-3">
            <label htmlFor="promptCategory" className="block font-bold text-sm mb-1">
              Categoria:
            </label>
            <select
                id="promptCategory"
                className="border rounded w-full p-2"
                value={selectedCategory}
                onChange={handleCategoryChange}
            >
              <option value="">-- Selecione --</option>
              {categories.map((cat) => (
                  <option key={cat.id} value={cat.id}>
                    {cat.title}
                  </option>
              ))}
            </select>
          </div>

          <ul className="border rounded bg-white">
            {prompts.length > 0 ? (
                prompts.map((prompt) => (
                    <li
                        key={prompt.id}
                        className="p-2 hover:bg-gray-200 cursor-pointer"
                        onClick={() => handlePromptClick(prompt.content)}
                    >
                      {prompt.title}
                    </li>
                ))
            ) : (
                <li className="p-2 text-gray-500 italic">
                  Nenhum prompt encontrado para esta categoria.
                </li>
            )}
          </ul>
        </div>
      </div>
  );
};

export default Prompt;
