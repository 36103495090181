import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getToken } from '../service/authService';

const { REACT_APP_API_ENDPOINT } = process.env;

const UpdatedPrompt: React.FC = () => {
    const [promptId, setPromptId] = useState<string>('');
    const [title, setTitle] = useState<string>('');
    const [content, setContent] = useState<string>('');
    const [categories, setCategories] = useState<any[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<string>('');
    const [prompts, setPrompts] = useState<any[]>([]);
    const token = getToken();

    // Carrega as categorias e a lista de prompts
    useEffect(() => {
        fetchCategories();
        fetchAllPrompts();
    }, [token]);

    // Busca todas as categorias de prompts
    const fetchCategories = async () => {
        try {
            const response = await fetch(`${REACT_APP_API_ENDPOINT}/categories/prompt`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if (!response.ok) throw new Error('Failed to fetch categories');
            setCategories((await response.json()) ?? []);
        } catch (error) {
            console.error('Error fetching categories:', error);
            setCategories([]);
        }
    };

    // Busca todos os prompts
    const fetchAllPrompts = async () => {
        try {
            const response = await fetch(`${REACT_APP_API_ENDPOINT}/prompts`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if (!response.ok) throw new Error('Failed to fetch prompts');
            setPrompts((await response.json()) ?? []);
        } catch (error) {
            console.error('Error fetching prompts:', error);
            setPrompts([]);
        }
    };

    // Busca prompts por categoria
    const fetchPromptsByCategory = async (categoryId: string) => {
        try {
            if (!categoryId) {
                fetchAllPrompts();
                return;
            }

            const response = await fetch(
                `${REACT_APP_API_ENDPOINT}/prompts/category/${categoryId}`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            if (!response.ok) throw new Error('Failed to fetch prompts by category');
            setPrompts((await response.json()) ?? []);
        } catch (error) {
            console.error('Error fetching prompts by category:', error);
            setPrompts([]);
        }
    };

    // Busca um prompt específico pelo ID
    const fetchPromptById = async (id: string) => {
        try {
            const response = await fetch(`${REACT_APP_API_ENDPOINT}/prompts/${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if (!response.ok) throw new Error('Failed to fetch prompt');
            const data = await response.json();
            setPromptId(data.id);
            setTitle(data.title ?? '');
            setContent(data.content ?? '');
            setSelectedCategory(data.categoryId ?? '');
        } catch (error) {
            console.error('Error fetching prompt:', error);
        }
    };

    // Handler para filtrar pelo combo de categorias
    const handleCategoryFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const categoryId = e.target.value;
        setSelectedCategory(categoryId);
        fetchPromptsByCategory(categoryId);
    };

    // Editar prompt
    const handleEdit = (id: string) => {
        fetchPromptById(id);
    };

    // Excluir prompt
    const handleDelete = async (id: string) => {
        try {
            const response = await fetch(`${REACT_APP_API_ENDPOINT}/prompts/${id}`, {
                method: 'DELETE',
                headers: { Authorization: `Bearer ${token}` },
            });
            if (!response.ok) throw new Error('Failed to delete prompt');
            fetchAllPrompts();
        } catch (error) {
            console.error('Error deleting prompt:', error);
        }
    };

    // Salva (cria ou atualiza) prompt
    const handleSave = async () => {
        const url = promptId
            ? `${REACT_APP_API_ENDPOINT}/prompts/${promptId}`
            : `${REACT_APP_API_ENDPOINT}/prompts`;
        const method = promptId ? 'PUT' : 'POST';

        // Converter para number
        const categoryIdAsNumber = selectedCategory ? parseInt(selectedCategory, 10) : null;

        const payload = {
            title,
            content,
            categoryId: categoryIdAsNumber, // categoryId deve ser do tipo number
        };

        try {
            const response = await fetch(url, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(payload),
            });
            if (!response.ok) {
                throw new Error(`Failed to ${promptId ? 'update' : 'create'} prompt`);
            }
            fetchAllPrompts();
            resetForm();
        } catch (error) {
            console.error('Error:', error);
        }
    };

    // Limpa o formulário
    const resetForm = () => {
        setPromptId('');
        setTitle('');
        setContent('');
        setSelectedCategory('');
    };

    return (
        <div className="p-8 bg-gray-100 min-h-screen">
            <h1 className="text-3xl font-bold mb-6 text-center">
                {promptId ? 'Edit Prompt' : 'Manage Prompts'}
            </h1>

            {/* Filtro de Categorias */}
            <div className="mb-6">
                <label htmlFor="categorySelect" className="block text-lg font-medium mb-2">
                    Filter by Category:
                </label>
                <select
                    id="categorySelect"
                    className="border p-2 w-full rounded"
                    value={selectedCategory}
                    onChange={handleCategoryFilter}
                >
                    <option value="">-- All Categories --</option>
                    {categories.map((category) => (
                        <option key={category.id} value={category.id}>
                            {category.title}
                        </option>
                    ))}
                </select>
            </div>

            {/* Formulário de Criação/Edição */}
            <div className="mb-6 bg-white p-6 rounded-lg shadow-md">
                <h2 className="text-2xl font-bold mb-4">
                    {promptId ? 'Edit Prompt' : 'Create Prompt'}
                </h2>
                <input
                    type="text"
                    placeholder="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="border p-2 mb-4 w-full rounded"
                />
                <textarea
                    placeholder="Content"
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    className="border p-2 mb-4 w-full rounded"
                    rows={4}
                />
                <select
                    id="categoryCombo"
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    className="border p-2 mb-4 w-full rounded"
                >
                    <option value="">-- Select Category --</option>
                    {categories.map((category) => (
                        <option key={category.id} value={category.id}>
                            {category.title}
                        </option>
                    ))}
                </select>
                <div className="flex space-x-4">
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded"
                        onClick={handleSave}
                    >
                        {promptId ? 'Update' : 'Create'}
                    </button>
                    <button
                        className="bg-gray-500 hover:bg-gray-700 text-white py-2 px-4 rounded"
                        onClick={resetForm}
                    >
                        Cancel
                    </button>
                    <Link
                        to="/dashboard"
                        className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded"
                    >
                        Return to Dashboard
                    </Link>
                </div>
            </div>

            {/* Tabela de Prompts */}
            <div className="bg-white p-6 rounded-lg shadow-md">
                <h2 className="text-2xl font-bold mb-4">Prompts List</h2>
                <table className="table-auto w-full border-collapse border border-gray-300">
                    <thead>
                    <tr className="bg-gray-200">
                        <th className="border p-2">ID</th>
                        <th className="border p-2">Title</th>
                        <th className="border p-2">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {prompts.length > 0 ? (
                        prompts.map((prompt) => (
                            <tr key={prompt.id} className="hover:bg-gray-100">
                                <td className="border p-2 text-center">{prompt.id}</td>
                                <td className="border p-2">{prompt.title ?? 'N/A'}</td>
                                <td className="border p-2 flex justify-center space-x-2">
                                    <button
                                        className="bg-yellow-500 hover:bg-yellow-700 text-white py-1 px-3 rounded"
                                        onClick={() => handleEdit(prompt.id)}
                                    >
                                        Edit
                                    </button>
                                    <button
                                        className="bg-red-500 hover:bg-red-700 text-white py-1 px-3 rounded"
                                        onClick={() => handleDelete(prompt.id)}
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={3} className="text-center p-4">
                                No prompts available.
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default UpdatedPrompt;
