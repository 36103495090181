// src/components/Messages.tsx
import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import Message from "./Message";
import { addMessage, setResponse } from "../../store/slices/aiSlice";
import { sendAIRequest } from "../../service/aiService";
import { v4 as uuidv4 } from "uuid";

const Messages: React.FC = () => {
  const dispatch = useDispatch();

  // Mensagens do Redux
  const messages = useSelector((state: RootState) => state.ai.messages);

  // Outras infos para enviar pro AI
  const aiProvider = useSelector((state: RootState) => state.ai.aiProvider);
  const model = useSelector((state: RootState) => state.ai.model);
  const useHistory = useSelector((state: RootState) => state.ai.useHistory);
  const personaId = useSelector((state: RootState) => state.ai.selectedPersona);
  const currentSession = useSelector((state: RootState) => state.session.currentSession);

  // Scroll automático ao final
  const scrollRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  /**
   * handleSendAI
   * Recebe o conteúdo (ex: da mensagem AI anterior) e reenvia como se fosse um "prompt" do usuário
   */
  const handleSendAI = async (content: string) => {
    try {
      // 1) Cria a "mensagem de usuário"
      const userMessageObj = {
        id: uuidv4(),
        content,
        aiMessage: false,
        aiProvider,
        model,
        isSystem: personaId > 0 ? true : false,
        isRag: false,
        isPersona: personaId > 0,
      };
      dispatch(addMessage(userMessageObj));

      // 2) Monta o payload para a chamada
      const payload = {
        aiProvider,
        model,
        stream: false,
        user: content,
        system: '',
        rag: '',
        useHistory,
        chatHistory: '',
        sessionId: currentSession?.id || null,
        personaId,
      };

      // 3) Faz a requisição
      const response = await sendAIRequest(payload);
      dispatch(setResponse(response.content));

      // 4) Adiciona a mensagem AI (resposta) no redux
      const aiMessageObj = {
        id: uuidv4(),
        content: response.content,
        aiMessage: true,
        aiProvider: response.aiProvider,
        model: response.model,
        isSystem: response.isSystem,
        isRag: response.isRag,
        isPersona: response.isPersona,
      };
      dispatch(addMessage(aiMessageObj));
    } catch (error) {
      console.error("Erro ao reenviar mensagem:", error);
      // Ex.: Você pode mostrar um toast ou console.error
    }
  };

  return (
      <div className="chat_messages_container">
        {messages.map((msg, index) => (
            <Message
                key={msg.id}
                content={msg.content}
                aiMessage={msg.aiMessage}
                aiProvider={msg.aiProvider}
                model={msg.model}
                animate={index === messages.length - 1 && msg.aiMessage}
                // Passamos a função handleSendAI para cada Message
                onSendAI={handleSendAI}
            />
        ))}
        <div ref={scrollRef} />
      </div>
  );
};

export default Messages;
