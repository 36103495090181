import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getToken } from '../service/authService';

const { REACT_APP_API_ENDPOINT } = process.env;

const UpdatedCategory: React.FC = () => {
    const [categoryId, setCategoryId] = useState<string>(''); // Para saber se estamos editando ou criando
    const [title, setTitle] = useState<string>('');
    const [slug, setSlug] = useState<string>('');
    const [persona, setPersona] = useState<boolean>(false);
    const [prompt, setPrompt] = useState<boolean>(false);

    // Lista de categorias
    const [categories, setCategories] = useState<any[]>([]);

    const token = getToken();

    // Carrega todas as categorias ao montar
    useEffect(() => {
        fetchAllCategories();
    }, [token]);

    /**
     * Busca todas as categorias
     */
    const fetchAllCategories = async () => {
        try {
            const response = await fetch(`${REACT_APP_API_ENDPOINT}/categories`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch categories');
            }

            const data = await response.json();
            setCategories(data ?? []);
        } catch (error) {
            console.error('Error fetching categories:', error);
            setCategories([]);
        }
    };

    /**
     * Busca uma categoria pelo ID para edição
     */
    const fetchCategoryById = async (id: string) => {
        try {
            const response = await fetch(`${REACT_APP_API_ENDPOINT}/categories/${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch category');
            }

            const data = await response.json();
            // Preenche os campos do formulário
            setCategoryId(data.id);
            setTitle(data.title ?? '');
            setSlug(data.slug ?? '');
            setPersona(data.persona ?? false);
            setPrompt(data.prompt ?? false);
        } catch (error) {
            console.error('Error fetching category:', error);
        }
    };

    /**
     * Excluir uma categoria
     */
    const handleDelete = async (id: string) => {
        try {
            const response = await fetch(`${REACT_APP_API_ENDPOINT}/categories/${id}`, {
                method: 'DELETE',
                headers: { Authorization: `Bearer ${token}` },
            });

            if (!response.ok) {
                throw new Error('Failed to delete category');
            }

            // Recarrega a lista de categorias
            fetchAllCategories();
        } catch (error) {
            console.error('Error deleting category:', error);
        }
    };

    /**
     * Salvar uma categoria
     * - Se categoryId estiver preenchido, é edição (PUT)
     * - Se categoryId estiver vazio, é criação (POST)
     */
    const handleSave = async () => {
        // Monta a URL e método com base se é edição ou criação
        const url = categoryId
            ? `${REACT_APP_API_ENDPOINT}/categories/${categoryId}`
            : `${REACT_APP_API_ENDPOINT}/categories`;
        const method = categoryId ? 'PUT' : 'POST';

        // Monta o objeto payload
        const payload = {
            title,
            slug,
            persona,
            prompt,
        };

        try {
            const response = await fetch(url, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error(`Failed to ${categoryId ? 'update' : 'create'} category`);
            }

            // Recarrega a lista de categorias e limpa o formulário
            fetchAllCategories();
            resetForm();
        } catch (error) {
            console.error('Error:', error);
        }
    };

    /**
     * Editar categoria
     */
    const handleEdit = (id: string) => {
        fetchCategoryById(id);
    };

    /**
     * Reseta o formulário
     */
    const resetForm = () => {
        setCategoryId('');
        setTitle('');
        setSlug('');
        setPersona(false);
        setPrompt(false);
    };

    return (
        <div className="p-8 bg-gray-100 min-h-screen">
            <h1 className="text-3xl font-bold mb-6 text-center">
                {categoryId ? 'Edit Category' : 'Manage Categories'}
            </h1>

            {/* Formulário de Criação/Edição */}
            <div className="mb-6 bg-white p-6 rounded-lg shadow-md">
                <h2 className="text-2xl font-bold mb-4">
                    {categoryId ? 'Edit Category' : 'Create Category'}
                </h2>

                {/* Campo TITLE */}
                <input
                    type="text"
                    placeholder="Título"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="border p-2 mb-4 w-full rounded"
                />

                {/* Campo SLUG */}
                <input
                    type="text"
                    placeholder="Slug"
                    value={slug}
                    onChange={(e) => setSlug(e.target.value)}
                    className="border p-2 mb-4 w-full rounded"
                />

                {/* Checkbox persona */}
                <div className="mb-4">
                    <label className="mr-2 font-medium">Persona:</label>
                    <input
                        type="checkbox"
                        checked={persona}
                        onChange={() => setPersona((prev) => !prev)}
                    />
                </div>

                {/* Checkbox prompt */}
                <div className="mb-4">
                    <label className="mr-2 font-medium">Prompt:</label>
                    <input
                        type="checkbox"
                        checked={prompt}
                        onChange={() => setPrompt((prev) => !prev)}
                    />
                </div>

                <div className="flex space-x-4">
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded"
                        onClick={handleSave}
                    >
                        {categoryId ? 'Update' : 'Create'}
                    </button>
                    <button
                        className="bg-gray-500 hover:bg-gray-700 text-white py-2 px-4 rounded"
                        onClick={resetForm}
                    >
                        Cancel
                    </button>
                    <Link
                        to="/dashboard"
                        className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded"
                    >
                        Return to Dashboard
                    </Link>
                </div>
            </div>

            {/* Tabela de Categorias */}
            <div className="bg-white p-6 rounded-lg shadow-md">
                <h2 className="text-2xl font-bold mb-4">Categories List</h2>
                <table className="table-auto w-full border-collapse border border-gray-300">
                    <thead>
                    <tr className="bg-gray-200">
                        <th className="border p-2">ID</th>
                        <th className="border p-2">Title</th>
                        <th className="border p-2">Slug</th>
                        <th className="border p-2">Persona?</th>
                        <th className="border p-2">Prompt?</th>
                        <th className="border p-2">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {categories.length > 0 ? (
                        categories.map((cat) => (
                            <tr key={cat.id} className="hover:bg-gray-100">
                                <td className="border p-2 text-center">{cat.id}</td>
                                <td className="border p-2">{cat.title}</td>
                                <td className="border p-2">{cat.slug}</td>
                                <td className="border p-2 text-center">{cat.persona ? 'Yes' : 'No'}</td>
                                <td className="border p-2 text-center">{cat.prompt ? 'Yes' : 'No'}</td>
                                <td className="border p-2 flex justify-center space-x-2">
                                    <button
                                        className="bg-yellow-500 hover:bg-yellow-700 text-white py-1 px-3 rounded"
                                        onClick={() => handleEdit(cat.id)}
                                    >
                                        Edit
                                    </button>
                                    <button
                                        className="bg-red-500 hover:bg-red-700 text-white py-1 px-3 rounded"
                                        onClick={() => handleDelete(cat.id)}
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={6} className="text-center p-4">
                                No categories available.
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default UpdatedCategory;
